<template>
    <Overlay v-if="isLoading" />
    <h5 v-if="!hideHeader">{{ $t('Import') }}</h5>

    <div :class="containerClass" v-bind="$attrs">
        <div v-if="!hideImportButton" class="btn-group" role="group">
            <button class="dropdown-toggle" :class="buttonClass" data-bs-toggle="dropdown">
                {{ $t('Download Template') }}
            </button>
            <ul class="dropdown-menu">
                <li v-if="dataObject.allowInsert"><button class="dropdown-item"
                        @click="selectLayout('Import')">{{ $t('Download Import Template') }}</button></li>
                <li v-if="dataObject.allowUpdate&&!props.importDataProps?.hideUploadTemplate"><button class="dropdown-item"
                        @click="selectLayout('Update')">{{ $t('Download Update Template') }}</button></li>
            </ul>
        </div>
        <!--<FileImportButton :disabled="!dataObject.allowInsert || !dataObject.allowUpdate" :dataObject="dataObject" class="btn btn-outline-primary btn-sm"
            accept="'xlsx'" disableChunks @error="()=>alert($t('There was an error in one or more rows when uploading.')+'\n'+$t('View DataLog for more information.'))">
            {{$t('Upload From Excel')}}
        </FileImportButton>-->
        <ImportTemplateBtn :disabled="!dataObject.allowInsert || !dataObject.allowUpdate" :dataObject="dataObject"
            :class="buttonClass" :batch="gridRef.importDataBatch ?? importDataBatch" :hideDataLog="hideDataLog"
            :openDataLog="openDataLog">
            {{ $t('Upload From Excel') }}
        </ImportTemplateBtn>
        <button v-if="!hideDataLog" :class="buttonClass" @click="openDataLog()">{{ $t('View DataLog') }}</button>
    </div>
    <ImortDataLogModal v-if="!hideDataLog" ref="imortDataLogModalRef" :dataObject="dataObject" />
</template>

<script setup >
import { Overlay } from 'o365-ui-components';
import { ExportConfig } from 'o365-data-export';
import { DataColumn } from 'o365-datagrid';
import { ref, reactive, computed } from 'vue';
import ImortDataLogModal from './ImortDataLogModal.vue';
import ImportTemplateBtn from './ImportTemplateBtn.vue';
//getLayoutsDataObject(props.dataObject)
const props = defineProps({
    dataObject: null,
    gridRef: null,
    columns: null,
    hideHeader: Boolean,
    hideDataLog: Boolean,
    importDataProps: {
        type: Object,
        required: false,
        default: null
    },
    hideImportButton: Boolean,
    importDataBatch: Boolean,
    containerClass: {
        default: 'd-flex flex-wrap gap-2 min-h-unset'
    },
    buttonClass: {
        default: 'btn btn-outline-primary btn-sm'
    }
});

const isLoading = ref(false),
    exportConfig = reactive(new ExportConfig());
let dataObject = props.dataObject ? props.dataObject : props.gridRef.dataObject;
const imortDataLogModalRef = ref(null);
const selectedTemplateType = ref(null);
exportConfig.sheetName = dataObject?.viewName?.split('_')?.pop() ?? 'Excel Template';

function updateColumns() {
    const arr = [];
    if (props.gridRef || props.columns) {
        (props.columns ?? props.gridRef.dataColumns.columns).forEach(col => {
            let vField = dataObject.fields[col.name];
            if ((!col.name.startsWith("o365")) || col.required) {
                arr.push(new DataColumn({
                    name: col.name,
                    field: col.field,
                    colId: col.colId,
                    headerName: col.headerName,
                    caption: col.caption ?? col.name,
                    width: col._width,
                    type: vField?.type ?? col.dataType,
                    format: col.format,
                    hide: !col.shown,
                    link: col.cellRenderer === "OLink" ? col.cellRendererParams : null,
                    summaryAggregate: col.summaryAggregate,
                    parentGroupId: col.parentGroupId,
                    required: col.required
                }));
            }
        });
    }
    return arr;
}
const columns = ref(null)
const populateExportConfig = (columns) => {
    const arr = [];
    const parseToString = (pValue) => {
        if (typeof pValue == 'number') {
            return `${pValue}`;
        } else {
            return pValue;
        }
    }
    for (const col of columns) {
        if (col.shown || col.required) {
            let vField = dataObject.fields[col.field];
            if (vField == null && col.colId.startsWith('Property.')) {
                vField = {
                    caption: col.headerName,
                    type: col.type,
                }
            }
            //}else */
            //if (col.name.includes('.')){continue;}
            arr.push({
                name: col.name,
                field: col.field,
                colId: col.colId,
                headerName: parseToString(col.headerName),
                caption: parseToString(vField?.caption),
                width: col._width,
                dataType: vField?.type,
                maxLength: vField?.maxLength,
                format: col.format,
                shown: col.required || col.shown,
                hide: col.required ? false : col.hide,
                link: col.cellRenderer === "OLink" ? col.cellRendererParams : null,
                summaryAggregate: col.summaryAggregate,
                parentGroupId: col.parentGroupId,
                required: col.required
            });
        }
    }
    return arr;
}

const selectLayout = async (templateType) => {
    selectedTemplateType.value = templateType;
    await downloadTemplate()
}
const openDataLog = () => {
    imortDataLogModalRef.value.modalRef.show();
}
const downloadTemplate = async () => {
    isLoading.value = true;
    columns.value = updateColumns();
    exportConfig.fileName = selectedTemplateType.value + "_template_" + dataObject?.export.getFileName() ?? dataObject.viewName.split("_").pop();
    exportConfig.columns = populateExportConfig(columns.value);

    if (selectedTemplateType.value === 'Update') {

        exportConfig.includeDataTypes = true;
        exportConfig.exportAsImportTemplate = false;
        exportConfig.exportAsUpdateTemplate = true;
        const uniqueField = exportConfig.columns.find(x => x.name == dataObject.fields.uniqueField);
        if (!uniqueField) {
            var vUniqueField = dataObject.fields[dataObject.fields.uniqueField];
            exportConfig.columns.unshift({
                name: vUniqueField.name,
                field: vUniqueField.name,
                headerName: vUniqueField.caption,
                caption: vUniqueField.caption,
                shown: true,
                hide: false,
                dataType: vUniqueField.type,
                maxLength: vUniqueField.maxLength,
                required: true


            })
        } else {
            uniqueField.required = true;
        }
    } else if (selectedTemplateType.value === 'Import') {
        exportConfig.includeDataTypes = true;
        exportConfig.exportAsImportTemplate = true;

    }

    if (props.gridRef?.dataObject) {
        props.gridRef.dataObject.emit('BeforeExportTemplate', selectedTemplateType.value, exportConfig);
    } else if (props.dataObject) {
        props.dataObject.emit('BeforeExportTemplate', selectedTemplateType.value, exportConfig);
    }

    await dataObject.export.exportData(exportConfig, null, 'xlsx').then((x) => { isLoading.value = false; }).catch((error) => {
        isLoading.value = false;
    });
    selectedTemplateType.value = null;
}
</script>